





















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ManualPODPhotosController'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import CloseCircle from '@/app/ui/assets/close_circle_alt.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue' 
import IconLoading from '@/app/ui/assets/icon_loading.vue'
import ModalUploadManualPOD from '../components/Modals/SuccessUploadManualPOD.vue'

@Component({
  components: {
    TextInput,
    Button,
    ImageUpload,
    ModalUploadManualPOD,
    CloseCircle,
    IconWarningTriangle,
    IconLoading,
  },
})
export default class ManualPODPhotosPage extends Vue {
  controller = controller
  parameters = {
    keyword: ''
  }
  numberPackage = ''
  proofPODPhoto = (<File[] | Array<undefined>>[])

  private onClear() {
    this.parameters.keyword = ''
  }

  private onCheck() {
    controller.setErrorMessage('')
    controller.getStatus(this.parameters.keyword)
  }

  private onUploadPhoto() {
    const payload = {
      packageId: this.parameters.keyword,
      proof1: this.proofPODPhoto[0],
      proof2: this.proofPODPhoto[1],
      proof3: this.proofPODPhoto[2],
      proof4: this.proofPODPhoto[3]
    }
    controller.uploadImagePOD(payload)
  }

  private onInputImage(file: File) {
    if (file) {
      (<File[]>this.proofPODPhoto).push(<File>file)
    }
  }

  private onRemoveImage(index: number) {
    this.proofPODPhoto.splice(index, 1)
  }

  private onCloseModal() {
    this.parameters.keyword = ''
    this.proofPODPhoto.splice(0, this.proofPODPhoto.length)
    controller.setIsSuccessUpload(false)
  }

  @Watch('controller.numberPackage')
  getNumberPackage(val: string): void {
    this.numberPackage = val
  }

}
