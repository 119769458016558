import { UploadPODPhotosRequestInterface } from '../contracts/ManualPODPhotosRequest'


export class UploadPODPhotosRequest
  implements UploadPODPhotosRequestInterface {
  public package_id: string
  public proof1?: Blob | File | null
  public proof2?: Blob | File | null
  public proof3?: Blob | File | null
  public proof4?: Blob | File | null

  constructor(
    package_id: string,
    proof1?: Blob | File | null,
    proof2?: Blob | File | null,
    proof3?: Blob | File | null,
    proof4?: Blob | File | null,
  ) {
    this.package_id = package_id
    this.proof1 = proof1
    this.proof2 = proof2
    this.proof3 = proof3
    this.proof4 = proof4
  }

  public toPayload(): FormData {
    const form = new FormData()

    form.append('package_id', this.package_id)
    form.append('proof1', <File>this.proof1 || '')
    form.append('proof2', <File>this.proof2 || '')
    form.append('proof3', <File>this.proof3 || '')
    form.append('proof4', <File>this.proof4 || '')
    return form
  }
}
