import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { ManualPODPhotosPresenter } from '../presenters/ManualPODPhotosPresenter'
import { UploadPODPhotosRequest } from '@/data/payload/api/ManualPODPhotosRequest'

export interface ManualPODPhotosState {
  isLoading: boolean;
  isLoadingUpload: boolean;
  isWithProofFile: boolean;
  errorMessage: string;
  numberPackage: string;
  isSuccessUpload: boolean;
}

@Module({ namespaced: true, store, name: 'claim', dynamic: true })
class ManualPODPhotosController extends VuexModule implements ManualPODPhotosState {
  private presenter: ManualPODPhotosPresenter = container.resolve(ManualPODPhotosPresenter)
  public isLoading = false
  public isLoadingUpload = false
  public isWithProofFile = false
  public errorMessage = ''
  public numberPackage = ''
  public isSuccessUpload = false

  @Action({ rawError: true })
  public getStatus(id: string) {
    this.setLoading(true)
    this.setNumberPackage('')
    this.presenter
      .get(id)
      .then(res => {
        const { isWithProofFile } = res
        if (!isWithProofFile) this.setNumberPackage(id)
        this.setIsWithProofFile(isWithProofFile)
      })
      .catch(error => {
        const { error_id, message } = error.data
        if (error_id && error_id === 400) {
          this.setErrorMessage(message.id)
        } else {
          Vue.notify({
            title: 'Fetch Claim Failed',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      }).finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public uploadImagePOD(payload: {
    packageId: string,
    proof1: File | undefined,
    proof2: File | undefined,
    proof3: File | undefined,
    proof4: File | undefined,
  }) {
    this.setLoadingUpload(true)
    this.presenter
      .uploadImage(new UploadPODPhotosRequest(
        payload.packageId,
        payload.proof1,
        payload.proof2,
        payload.proof3,
        payload.proof4
      ))
      .then(res => {
        this.setNumberPackage('')
        this.setIsSuccessUpload(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Claim Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingUpload(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingUpload(bool: boolean) {
    this.isLoadingUpload = bool
  }

  @Mutation
  public setErrorMessage(message: string) {
    this.errorMessage = message
  }

  @Mutation
  private setNumberPackage(value: string) {
    this.numberPackage = value
  }

  @Mutation
  private setIsWithProofFile(bool: boolean) {
    this.isWithProofFile = bool
  }

  @Mutation
  public setIsSuccessUpload(bool: boolean) {
    this.isSuccessUpload = bool
  }
}

export default getModule(ManualPODPhotosController)
